import styled from 'styled-components';

import * as colors from '@hub/design-tokens/colors';

import { IVerticalDividerProps } from './VerticalDivider.types';

export const VerticalDividerContainerStyles = styled.div<IVerticalDividerProps>`
  padding: ${({ offset }) =>
    typeof offset === 'number' ? `${offset}px` : offset?.map((p) => (p !== null ? `${p}px` : p)).join(' ')};
  padding-left: ${({ offsetLeft }) => offsetLeft && `${offsetLeft}px`};
  padding-right: ${({ offsetRight }) => offsetRight && `${offsetRight}px`};
  padding-top: ${({ offsetTop }) => offsetTop && `${offsetTop}px`};
  padding-bottom: ${({ offsetBottom }) => offsetBottom && `${offsetBottom}px`};
  height: ${({ height = 16, fullHeight }) => (fullHeight ? '100%' : `${height}px`)};
`;

export const VerticalDividerStyles = styled.div<IVerticalDividerProps>`
  border-left: ${({ color = colors.ColorNeutral300 }) => `1px solid ${color}`};
  width: 1px;
  height: ${({ height = 16, fullHeight }) => (fullHeight ? '100%' : `${height}px`)};
`;

export const VerticalDivider = ({
  height,
  color,
  offset,
  offsetLeft,
  offsetTop,
  offsetRight,
  offsetBottom,
  fullHeight = false,
}: IVerticalDividerProps) => {
  return (
    <VerticalDividerContainerStyles
      height={height}
      offset={offset}
      offsetLeft={offsetLeft}
      offsetTop={offsetTop}
      offsetBottom={offsetBottom}
      offsetRight={offsetRight}
      color={color}
      fullHeight={fullHeight}
    >
      <VerticalDividerStyles height={height} color={color} fullHeight={fullHeight} />
    </VerticalDividerContainerStyles>
  );
};
